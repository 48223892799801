<template>
  <div class="custom-select" :class="{disabled: disabled || selected == defaultOption}">
    <select :name="filterType" :id="filterType" v-model="selected" @change="toggleChange">
      <option :value="defaultOption" disabled selected hidden>{{defaultOption}}</option>
      <option v-for="item in items" :value="item.id" :key="item.id">{{item.name}}</option>
    </select>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      required: true,
    },
    filterType: {
      type: String,
      required: true,
      default: ""
    },
    defaultOption: {
      type: String,
      required: true
    },
    disabled:{
      type: Boolean,
      required: true,
      default: false
    }
  },
  data() {
    return {
      selected: ''
    };
  },
  watch:{
    disabled(isDisabled){
      if(isDisabled){
        this.selected = this.defaultOption;
      }
      this.disabled = isDisabled;
    },
    selected(data){
      this.selected = data;
    }
  },
  methods:{
    toggleChange(){
      if(this.selected === 'remove'){
        this.selected = this.defaultOption;
      }
      this.$emit('filterChange',this.selected);
    }
  },
  mounted(){
    this.selected = this.defaultOption
  }
};
</script>

<style lang="scss" scoped>

.disabled{
  background: var(--gray414241) !important;
}

select{
  appearance: none;
  background-color: transparent;
  border: none;
  padding: 0.5em 0.5em;
  margin: 0;
  width: 100%;
  font-family: inherit;
  font-size: inherit;
  cursor: inherit;
  line-height: 1.7;
  outline: none;
  text-align: center;
  color: $white;
  text-align-last: center;
}

select::-ms-expand {
    display: none;
}

option{
  color: initial;
}

.custom-select{
  border: none;
  background: var(--redC41230);
  color: var(--whiteFFFFFF);
  // padding: 0.8rem 3rem;
  border-radius: 50px;
  cursor: pointer;
  font-size: 16px;
  letter-spacing: 1px;
  width: 100%;
  max-width: 20ch;
  margin-right: 30px;
}

</style>

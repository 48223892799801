<template>
  <ContentWrapper>
    <template v-slot:title>Leaderboard</template>
    <Container>
      <h3>Time Interval</h3>
      <div class="sort-options">

      <ButtonWithSelect 
          :items="fixedWeeklyList" 
          filterType="week" 
          defaultOption="Weekly"
          @filterChange="updateFilter($event,'weekFilter')"
          :disabled="weekIsDisabled"
          ref="weekComponent"
        />

      <ButtonWithSelect 
            :items="fixedMonthList" 
            filterType="month" 
            defaultOption="Monthly"
            @filterChange="updateFilter($event,'monthFilter')"
            :disabled="monthIsDisabled"
          
          />
          <ButtonWithSelect 
            :items="fixedYearList" 
            filterType="year" 
            defaultOption="Yearly"
            @filterChange="updateFilter($event,'yearFilter')"
            :disabled="yearIsDisabled"
          />

          <SubmitButton @onButtonClick="allTimeToggle" :disabled="allTimeIsDisabled">All Time</SubmitButton>
      </div>
      <div class="selectbox-wrapper">
        <label for="leaderboard">Select Leaderboard: </label>
        <DropdownSelect  
          id="leaderboard" 
          :options="this.options"
          defaultOption="Exchange" 
          @change="updateExchange($event)"
          :selected="this.leaderboardId"
        />
      </div>
      <div class="table-container">
        <table>
          <thead>
            <tr>
              <th class="row-1">Ranking</th>
              <th class="row-1">Display Name</th>
              <th class="row-1">Accumulative ROI</th>
            </tr>
          </thead>
          <tbody v-if="specificLeaderboard != null">
            <tr v-for="(data,index) in specificLeaderboard.gameSessions.data" :key="data.user_id">
              <td :class="{isUser: getUserProfile.id == data.user_id}">{{index + 1}}</td>
              <td :class="{isUser: getUserProfile.id == data.user_id}">{{data.user.display_name || `Player ${index + 1}`}}</td>
              <td :class="{isUser: getUserProfile.id == data.user_id}" :style="{color: parseFloat(data.roi) > 0.00 ? '#009F00' : '#C60000'}">{{data.roi}}%</td>
            </tr>
          </tbody>
        </table>
      </div>
    </Container>
  </ContentWrapper>
</template>

<script>
import Container from "@/components/Container";
import SubmitButton from "@/components/SubmitButton";
import ButtonWithSelect from "@/components/ButtonWithSelect";
import DropdownSelect from "@/components/DropdownSelect";

import moment from 'moment'

export default {
  components: {
    Container,
    SubmitButton,
    ButtonWithSelect,
    DropdownSelect
  },
  data() {
    return {
      options: [],
      weekFilter: '',
      monthFilter: '',
      yearFilter: '',
      weekIsDisabled : false,
      monthIsDisabled : false,
      yearIsDisabled : false,
      allTimeIsDisabled: true,
      selectedExchange: '',
      leaderboardId: 0,
      fixedWeeklyList:[],
      fixedMonthList: [
        {
          id: 1,
          name : 'January'
        },
        {
          id: 2,
          name : 'February'
        },
        {
          id: 3,
          name : 'March'
        },
        {
          id: 4,
          name : 'April'
        },
        {
          id: 5,
          name : 'May'
        },
        {
          id: 6,
          name : 'Jun'
        },
        {
          id: 7,
          name : 'July'
        },
        {
          id: 8,
          name : 'August'
        },
        {
          id: 9,
          name : 'September'
        },
        {
          id: 10,
          name : 'October'
        },
        {
          id: 11,
          name : 'November'
        },
        {
          id: 12,
          name : 'December'
        },
      ],
      fixedYearList: [
        {
          id: 2021,
          name : "2021"
        }
      ],
    };
  },
  methods: {
    updateFilter(data,attr){
      this[attr] = data;

      this.getUpdatedLeaderboard(attr,data);
    },
    updateExchange(data){
      if(this.userRole === 'admin'){
        this.$router.replace(`/admin/leaderboard/${data}`);
        return
      }
      this.$router.replace(`/leaderboard/${data}`);
    },
    getUpdatedLeaderboard(changeProperty,changedValue){
      let params = null;

      if(this.yearFilter == 'Yearly' || this.monthFilter == 'Monthly' || this.yearFilter == 'Weekly'){
        this.$store.dispatch('callLeaderboardList',{});
        return;
      };

      if(changeProperty == 'monthFilter'){
        params = {
          month: this.monthFilter
        };
        [this.allTimeIsDisabled,this.yearIsDisabled,this.weekIsDisabled] = [true,true,true];
        this.monthIsDisabled = false;
      }else if(changeProperty == 'yearFilter'){
        params = {
          year: this.yearFilter
        };
        [this.allTimeIsDisabled,this.monthIsDisabled,this.weekIsDisabled] = [true,true,true];
        this.yearIsDisabled = false;
      }else if(changeProperty == 'weekFilter'){
        params = {
          week: this.weekFilter
        };
        [this.allTimeIsDisabled,this.yearIsDisabled,this.monthIsDisabled] = [true,true,true];
        this.weekIsDisabled = false;
      }

      this.$store.dispatch('callLeaderboardList',{params});
    },
    allTimeToggle(){
      this.allTimeIsDisabled = !this.allTimeIsDisabled;
      this.weekIsDisabled = true;
      this.yearIsDisabled = true;
      this.monthIsDisabled = true;
      this.$store.dispatch('callLeaderboardList',{});
    },
    dateOfSpecificWeek(week){
      return moment().isoWeek(week).startOf('isoWeek').format('DD MMM YYYY')
    }
  },
  computed:{
    leaderbordList(){
      return this.$store.getters.getLeaderboardList.map(leaderboard => leaderboard.game);
    },
    specificLeaderboard(){
      return this.$store.getters.getLeaderboardList.filter(leaderboard => leaderboard.game.id == this.$route.params.id)[0] || null;
    },
    getUserProfile(){
      return this.$store.getters.getUserProfile;
    },
    weeksInYear(){
      return moment().weeksInYear();
    }
  },
  created(){
    const params = {
      week: moment().isoWeek()
    };

    this.$store.dispatch('callLeaderboardList',{params});

    this.options = this.leaderbordList;
    this.leaderboardId = this.$route.params.id;
    this.fixedWeeklyList = [...Array(this.weeksInYear).keys()].map((index) =>  ({
      id: index + 1,
      name: `Week ${index + 1}: ${this.dateOfSpecificWeek(index + 1)}`
    }));
  },
  mounted(){
    this.$refs.weekComponent.selected = moment().isoWeek();
  },
  watch:{
    leaderbordList(newData){
      this.options = newData;
    },
    specificLeaderboard(newData){
      this.selectedExchange = newData;
    },
    "$route.params.id":{
      handler:function(data){
        this.leaderboardId = data;
      },  
      immediate: true
    }
  }
};
</script>

<style lang="scss" scoped>

.table-container{
  @media screen and (max-width: 450px){
    max-width: 100vw;
    overflow: auto;
  }
}

h3{
  @media screen and (max-width:450px){
    display: none;
  }
}

.content-container{
  @media screen and (max-width:450px){
    padding: 0;
    height: calc(100vh - 60px);
  }
}
.sort-options {
  display: flex;
  flex-wrap: wrap;
  margin: 20px 0;

  @media screen and (max-width:450px){
    margin: 0;
    
    & > *{
      border-radius: 0;
      margin: 0;
      flex: 1 1 100px
    }
  }
}
.selectbox-wrapper {
  display: grid;
  grid-template-columns: 200px 300px;
  margin-bottom: 2rem;
  align-items: center;

  @media screen and (max-width:550px){
    grid-template-columns: 1fr;
    margin: 1rem;
  }
}
table{
  border-collapse: collapse;
  background-color: grey;
  border: 1px solid #EEEEEE;
  border-radius: 20px;
  text-align: left;
  table-layout: fixed;
  white-space: nowrap;
  th{
    background-color: #414241;
    // text-transform: uppercase;
    letter-spacing: 0.1rem;
    padding: $twelve 2rem;
    font-size: $fourteen;
    color: white;

    span{
      float: right;
    }
  }
  td{
    color: black;
    background-color: white;
    padding: 1rem 2rem;
  }

  @media screen and (max-width:550px) {
    th, td{
      padding: 1rem;
    }
  }

  .row-1{
  width: 20%;
  }
  .row-2{
  width: 20%;
  }
  .row-3{
  width: 20%;
  }
  .row-4{
  width: 10%;
  }
  .row-5{
  width: 10%;
  }
  .row-6{
  width: 20%;
  }

  .isUser{
    background: var(--redC41230) !important;
    color: white !important;
  }
}

/deep/ .input-container .input-wrapper select{
  padding-left: 1rem !important;
}
</style>
